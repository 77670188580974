<template>
  <div>
    <form-component v-if="form.id" :form="form" />
  </div>
</template>

<script>
import FormComponent from "./components/FormComponent.vue";

export default {
  components: {
    FormComponent,
  },
  data() {
    return {
      form: {
        email: "",
        jobTitle: "",
        category: "",
        vacancy: "",
        location: "",
        food: null,
        checked: [],
      },
      show: true,
    };
  },
  created(){
    const id = this.$route.params.id;
    this.getJob(id);
  },
  methods: {
    
    getJob(id) {
      let query = {
        id: id,
      };
      this.$store.dispatch("job/getJob", query).then((res) => {
        this.form = res.data;
      });
    },
  },

};
</script>

<style scoped></style>
